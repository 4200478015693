import React, { useContext, useState, useEffect } from 'react';
import ShopItems from '../../pages/Shop/shop-items';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { CartContext } from '../../components/Context/cart/CartContext';
import useAxios from 'scripts/hooks/useAxios';


export default function OrderAdd() {
    const { t } = useTranslation()
    const { callApi } = useAxios();
    const { cartItems } = useContext(CartContext);

    const [chimneyStatus, setChimneyStatus] = useState('active');
    const [stoveStatus, setStoveStatus] = useState('active');
    const [othersStatus, setOthersStatus] = useState('active');
    const [onGoingMaintenance, setOnGoingMaintenance] = useState(false);

    useEffect(() => {
        let stoveFound = cartItems.find(item => item.ruleset?.toLowerCase() === "stove"),
            chimneyFound = cartItems.find(item => item.ruleset?.toLowerCase() === "chimney" || item.ruleset?.toLowerCase() === "premodul");

        setChimneyStatus(chimneyFound ? "inactive" : "active");
        setStoveStatus(stoveFound ? "inactive" : "active");

        callApi({
            method: 'get',
            url: '/api/order/maintenanceStatus',
        }).then((res) => {
            if (res.status === 200) {
                if (!res.data?.value[0]) return;
                let currentDate = Date.now(),
                    fromDate = new Date(res.data.value[0].ed_frommaintenancedate).getTime(),
                    toDate = new Date(res.data.value[0].ed_tomaintenancedate).getTime();

                if (res.data?.value[0]?.statuscode === 1 && fromDate <= currentDate && currentDate <= toDate) {
                    setChimneyStatus("inactive");
                    setStoveStatus("inactive");
                    setOthersStatus("inactive");
                    setOnGoingMaintenance(true)
                }
            }
        }).catch(error => {
            console.log("Error", error)
        })

    }, [cartItems, callApi])

    const categories = [
        {
            "title": t('orders.category-stoves-title'),
            //"description": t('orders.category-stoves-description'),
            "image": "/images/category-stoves.jpg",
            "status": stoveStatus,
            "to": {
                pathname: '/cpq',
                state: { ruleset: 'Stove' },
            }
        },
        {
            "title": t('orders.category-chimney-title'),
            //"description": t('orders.category-chimney-description'),
            "image": "/images/placeholder-product-2.png",
            "status": chimneyStatus,
            "to": {
                pathname: '/cpq',
                state: { ruleset: 'Chimney' },
            }
        },
        {
            "title": t('orders.category-premodul-title'),
            //"description": t('orders.category-premodul-description'),
            "image": "/images/placeholder-product-2.png",
            "status": chimneyStatus,
            "to": {
                pathname: '/cpq',
                state: { ruleset: 'Premodul' },
            }
        },
        {
            "title": t('orders.category-accessories-title'),
            "description": t('orders.category-accessories-description'),
            "status": othersStatus,
            "image": "/images/category-accessory.jpg",
            "to": "/shop/category/471B9F83-9332-4770-9AE9-33B3C8" // Övriga artiklar
        }
    ];

    return (
        <div className="main-content">
            <div className="primary-content">

                <h1>{t('orders.select-category')}</h1>
                {onGoingMaintenance && <h3 className='content-primary'>{t('orders.maintenance-text')}</h3>}
                <ShopItems items={categories} />
                <div className="content-primary">
                    <h3>{t('orders.category-message.information-text')}</h3>

                </div>
            </div>
        </div>
    );

}
